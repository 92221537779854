import { Component, OnInit, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as L from 'leaflet';
import { Subscription } from 'rxjs';
import { BoundingBox } from 'geocoordinate';

import { DatasourceService } from '../datasource.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  map: any = null;
  mapElement: any = null;
  apiRoot = 'https://api.experiments.chrkaatz.de/api';
  combinedSubscription: Subscription = null;
  combined: object[] = null;
  items: any = [];
  selectedSource: String = '';
  itemsToShow: any = [];
  isAutoFlying: Boolean = false;
  layers: any = [];

  constructor(private httpClient: HttpClient, private datasourceService: DatasourceService) {
    if (window.location.host.search('localhost') >= 0) {
      this.apiRoot = 'http://localhost:3000';
    }
  }


  translateLatLng(obj) {
    return {
      latitude: obj.lat,
      longitude: obj.lng
    };
  }

  ngOnInit() {
    this.mapElement = document.getElementById('map');
    this.map = L.map(this.mapElement);
  }

  ngAfterViewInit() {

    L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
      attribution: `&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
      &copy; <a href="http://cartodb.com/attributions">CartoDB</a>`,
      subdomains: 'abcd',
      maxZoom: 19
    }).addTo(this.map);
    this.map.setView([52.52, 13.40], 14);
    this.map.on('zoomend', this.showInfo.bind(this));
    this.map.on('moveend', this.showInfo.bind(this));
    this.showInfo(false);
    this.combinedSubscription = this.datasourceService.getCombined()
      .subscribe((combined: any) => {
        this.combined = combined;
      });
  }

  ngOnDestroy() {
    this.combinedSubscription.unsubscribe();
  }

  showInfo(ev) {
    if (!this.isAutoFlying) {
      const bbox = this.map.getBounds();
      const url = `${this.apiRoot}/at`;
      let params = new HttpParams();
      if (this.selectedSource && this.combined) {
        const activeSource: any = this.combined.filter((source: any) => source.sourceId === this.selectedSource)[0];
        params = new HttpParams({
          fromObject: {
            only: activeSource.subscriptions[0].subId
          }
        });
      }
      this.httpClient.post(url, {
        position: this.translateLatLng(ev.latlng || this.map.getCenter()),
        bbox: {
          northEast: this.translateLatLng(bbox._northEast),
          southWest: this.translateLatLng(bbox._southWest),
        }
      }, {
        params
      })
        .toPromise()
        .then(res => {
          const result: any = res;
          if (result.response && result.response.items && result.response.items.length > 0) {
            result.response.items.forEach(item => {
              if (this.items.filter(storedItem => storedItem.id === item.id).length > 0) {
                this.items = this.items.filter(storedItem => storedItem.id !== item.id);
              }
              this.items.push(item);
            });
            if (this.selectedSource) {
              this.draw(this.items.filter((item) => item.sourceId === this.selectedSource));
            } else {
              this.draw(this.items);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (ev.type === 'moveend' && this.isAutoFlying) {
      this.isAutoFlying = false;
    }
  }

  onSourceSelected(sourceId: String) {
    this.combined = this.combined.map((src: any) => {
      if (this.selectedSource !== sourceId && src.sourceId === sourceId) {
        src.isSelected = true;
        this.selectedSource = sourceId;
      } else if (this.selectedSource === sourceId && src.sourceId === sourceId) {
        src.isSelected = false;
        this.selectedSource = '';
      } else {
        src.isSelected = false;
      }
      return src;
    });
    if (this.selectedSource) {
      this.itemsToShow = this.items.filter((item) => item.sourceId === sourceId);
    } else {
      this.itemsToShow = this.items;
    }
    this.draw(this.itemsToShow, true);
  }

  draw(items, recenter: Boolean = false) {
    this.layers.forEach(l => l.remove());
    this.layers = [];
    for ( const item of items ) {
      let imageUrl = item.marker.imageUrl;
      if (item.marker.type === 'shape') {
        const src: any = this.combined.filter((source: any) => source.sourceId === item.sourceId)[0];
        if (src) {
          imageUrl = src.iconUrl;
        }
      }
      const itemIcon = L.divIcon({
        className: 'item-icon',
        html: `<div class="icon-marker">
          <img src="${imageUrl}"/>
        </div>`,
        iconSize: [46, 46]
      });

      const layer = L.marker([item.position.latitude, item.position.longitude], {icon: itemIcon})
        .bindPopup(`<div class="bubble">
          <h3>${item.name}</h3>
          <p>${item.details.texts.join('<br/>')}</p>
        </div>`);
      layer.addTo(this.map);
      this.layers.push(layer);
      if (recenter && items.length === 1) {
        this.isAutoFlying = true;
        this.map.flyTo([items[0].position.latitude, items[0].position.longitude]);
      } else if (recenter && items.length > 1) {
        this.isAutoFlying = true;
        const bbox = new BoundingBox();
        items.forEach((aPlace) => bbox.pushCoordinate(aPlace.position.latitude, aPlace.position.longitude));
        const theBox = bbox.box();
        this.map.flyToBounds(L.latLngBounds(
          [theBox.topLeftLatitude, theBox.topLeftLongitude],
          [theBox.bottomRightLatitude, theBox.bottomRightLongitude]
        ));
      }
    }
  }

}
