export class Datasource {
  public sourceName: string;
  public description: string;
  public source: object;
  public imageUrl: string;
  public iconUrl: string;
  // public hide: boolean;

  constructor(sourceObject: object) {
    this.source = sourceObject;
    // this.sourceName = sourceObject.sourceName;
    // this.description = description;
    // this.hide = true;
  }

  get() {
    return this.source;
  }

  // toggle() {
  //   this.hide = !this.hide;
  // }
}
