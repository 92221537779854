import { Component, Input, OnInit } from '@angular/core';
import { Datasource } from '../datasource';

@Component({
  selector: 'app-datasource',
  templateUrl: './datasource.component.html',
  styleUrls: ['./datasource.component.css']
})

export class DatasourceComponent implements OnInit {

  @Input('datasource') data: Datasource;

  constructor() { }

  ngOnInit() {
  }

}
