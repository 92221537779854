import { Component, AfterViewInit, OnDestroy } from '@angular/core';

import { DatasourceService } from '../datasource.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements AfterViewInit, OnDestroy {

  dataSourceSubscription: Subscription = null;
  datasources: object[];

  constructor(
    private datasourceService: DatasourceService
  ) {}

  ngAfterViewInit() {
    this.dataSourceSubscription = this.datasourceService.getSources()
      .subscribe((sources: any) => {
        this.datasources = sources;
      });
  }

  ngOnDestroy() {
    this.dataSourceSubscription.unsubscribe();
  }

}
