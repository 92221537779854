import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Datasource } from '../datasource';

@Component({
  selector: 'app-datasource-badge',
  templateUrl: './datasource-badge.component.html',
  styleUrls: ['./datasource-badge.component.css']
})
export class DatasourceBadgeComponent implements OnInit {

  @Input('datasource') data: Datasource;
  @Output() sourceClicked = new EventEmitter<string>();

  constructor() {
  }

  sourceClick(sourceId: string) {
    this.sourceClicked.emit(sourceId);
  }

  ngOnInit() {
  }

}
