import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { retry, map } from 'rxjs/operators';

import { Datasource } from './datasource';

@Injectable({
  providedIn: 'root'
})
export class DatasourceService {

  apiRoot = 'https://api.experiments.chrkaatz.de/api';
  sourcesEndpoint = '/sources';
  combinedEndpoint = '/combined';
  sources: Object[] = [];
  private datasourceObserver = new ReplaySubject(1);
  private combinedObserver = new ReplaySubject(1);

  constructor(private httpClient: HttpClient) {
    if (window.location.host.search('localhost') >= 0) {
      this.apiRoot = 'http://localhost:3000';
    }
  }

  public getSources(forceRefresh?: boolean) {
    if (!this.datasourceObserver.observers.length || forceRefresh) {
      this.httpClient.get(`${this.apiRoot}${this.sourcesEndpoint}?detailed=true`)
        .pipe(
          retry(3)
        )
        .subscribe(
          (data: any) => this.datasourceObserver.next(data.sources),
          error => {
            this.datasourceObserver.error(error);
            this.datasourceObserver = new ReplaySubject(1);
          }
        );
    }
    return this.datasourceObserver;
  }

  public getCombined(forceRefresh?: boolean) {
    if (!this.combinedObserver.observers.length || forceRefresh) {
      this.httpClient.get(`${this.apiRoot}${this.combinedEndpoint}`)
        .pipe(
          retry(3)
        )
        .subscribe(
          (data: any) => this.combinedObserver.next(data.combined),
          error => {
            this.combinedObserver.error(error);
            this.combinedObserver = new ReplaySubject(1);
          }
        );
    }
    return this.combinedObserver;
  }
}
